<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.formItem.student')">
						<el-input v-model="screenForm.student_name" clearable :placeholder="$t('teacher.placeholder.studentName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.account')">
						<el-input v-model="screenForm.user_name" clearable :placeholder="$t('teacher.placeholder.account')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopName')">
						<el-input v-model="screenForm.store_name" clearable :placeholder="$t('teacher.placeholder.shopName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.liveTitle')">
						<el-input v-model="screenForm.live_name" clearable :placeholder="$t('teacher.placeholder.liveTitle')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.liveType')">
						<el-select v-model="screenForm.is_force_close" class="w_all">
							<el-option v-for="(item,index) in typeOption" :key="index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<span class="search-clear" @click="handleClearSearch"><i class="el-icon-brush"></i>{{$t('teacher.actions.clear')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-list">
				<div class="item" v-for="(item, index) in pageList" :key="index">
					<div class="item-pic">
						<a :href="$itemBasePath+'/m/uulive/info/'+item.live_id" target="_blank">
							<img :src="item.cover0" alt="">
						</a>
						<div class="item-head">
							<div class="name">{{item.live_title}}</div>
							<div class="time">{{item.settime | parseTime()}}</div>
						</div>
						<div class="item-bottom">
							<div class="store-text"><i class="el-icon-s-shop"/>{{item.store_name}}</div>
						</div>
					</div>
					<div class="item-main">
						<div class="item-box">
							<div>{{item.real_name}}[<span>{{item.user_name}}</span>]</div>
							<div><i class="el-icon-s-flag"></i> {{item.class_name}}</div>
						</div>
						<el-button type="danger" size="mini" v-if="item.type==1" @click="viewChange(item)">{{$t('teacher.actions.reason')}}</el-button>
						<el-button type="primary" size="mini" @click="closeChange(item.live_id)" v-else>{{$t('teacher.actions.closeLive')}}</el-button>
					</div>
				</div>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
			<el-dialog :title="dialogType!=='view'?$t('teacher.dialogTitle.closeLive'):$t('teacher.dialogTitle.closeLive')" width="600px" :visible.sync="dialogVisible">
				<el-form :model="pageInfo" :rules="pageRules" ref="pageInfo" size="small" label-width="100px">
					<el-form-item :label="$t('teacher.formItem.bannedTime')" prop="disable_expire_time">
						<el-date-picker v-model="pageInfo.disable_expire_time" type="datetime" value-format="timestamp" :disabled="dialogType==='view'" :picker-options="pickerOptions" :placeholder="$t('teacher.placeholder.datetime')" />
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.closeLive')" prop="reason">
						<el-input type="textarea" v-model="pageInfo.reason" class="w400" :disabled="dialogType==='view'" :placeholder="$t('teacher.placeholder.closeLive')" rows="5" maxlength="255" show-word-limit></el-input>
					</el-form-item>
				</el-form>
				<div v-if="dialogType!=='view'" style="text-align: right;">
					<el-button @click="dialogVisible = false">{{$t('teacher.actions.cancel2')}}</el-button>
					<el-button type="primary" v-loading="loading" @click="handleSubmit">{{$t('teacher.actions.confirm2')}}</el-button>
				</div>
			</el-dialog>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchClass, getLiveBroadcastList, closeLive } from '@/api/teacher';
	const defaultScreen = {
		store_name: '',
		student_name: '',
		live_name: '',
		class_id: '',
		user_name: '',
		is_force_close: '0'
	}
	const defaultForm = {
		live_id: '',
		reason: '',
		disable_expire_time: Date.now()
	}
	export default {
		data() {
			return {
				screenForm: Object.assign({}, defaultScreen),
				classOption: [],
				typeOption: this.$t('teacher.option.liveType'),
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				pageInfo: Object.assign({}, defaultForm),
				pageRules: {
					disable_expire_time: [
						{ required: true, message: this.$t('teacher.validate.required'), trigger: 'change' }
					],
					reason: [
						{ required: true, message: this.$t('teacher.validate.required'), trigger: 'blur' }
					]
				},
				dialogType: 'close',
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7
					}
				},
				dialogVisible: false,
				loading: false,
			}
		},
		created() {
			this.getClassOption();
			this.getPageList();
		},
		methods: {
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				getLiveBroadcastList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClearSearch() {
				this.screenForm = Object.assign({}, defaultForm)
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			closeChange(id) {
				this.pageInfo = Object.assign({}, defaultForm)
				this.dialogType = 'close'
				this.pageInfo.live_id = id
				this.pageInfo.reason = ''
				this.dialogVisible = true
				this.$nextTick(() => {
					this.$refs.pageInfo.clearValidate()
				})
			},
			viewChange(item) {
				this.pageInfo = Object.assign({}, defaultForm)
				this.dialogType = 'view'
				this.pageInfo.live_id = item.live_id
				this.pageInfo.reason = item.remark
				this.pageInfo.disable_expire_time = item.disable_expire_time * 1000
				this.dialogVisible = true
				this.$nextTick(() => {
					this.$refs.pageInfo.clearValidate()
				})
			},
			handleSubmit() {
				this.loading = true;
				this.$refs.pageInfo.validate((valid)=>{
					if(valid){
						const params = {
							...this.pageInfo
						}
						params.disable_expire_time = parseInt(params.disable_expire_time / 1000)
						closeLive(params).then(() => {
							this.$message.success(this.$t('teacher.successMsg.closeLive'))
							this.loading = false
							this.dialogVisible = false
							this.getPageList()
						})
					}else {
						this.loading = false
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {
			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
			::v-deep .el-form {
				display: flex;
				flex-wrap: wrap;
				.el-form-item {
					width: 320px;
					flex-shrink: 0;
					display: flex;
					padding: 0 9px;
					margin-right: 0;
					margin-bottom: 16px;
					box-sizing: border-box;
					.el-form-item__label {
						min-width: 68px;
					}
					.el-form-item__content {
						flex: 1;
						width: 0;
					}
				}
			}
			.w100 {
				width: 100px;
			}
			.w_all {
				width: 100%;
			}

			.search-clear {
				cursor: pointer;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}
		}
		.t-page-list {
			.item {
				margin: 0 5px 10px;
				overflow: hidden;
				border-radius: 6px;
				display: inline-block;
				width: 220px;
				border: 1px solid #dadada;
				.item-pic {
					width: 100%;
					height: 220px;
					position: relative;
					img {
						width: 100%;
						height: 100%;
					}
					.item-head {
						top: 0;
						left: 0;
						right: 0;
						padding: 10px;
						position: absolute;
						display: flex;
						color: #fff;
						align-items: center;
						justify-content: space-between;
						background-color: rgba(0,0,0,.3);
					}
					.item-bottom {
						bottom: 0;
						left: 0;
						right: 0;
						padding: 10px;
						position: absolute;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: space-between;
						background-color: rgba(0,0,0,.3);
					}
				}
				.item-main {
					padding: 8px 12px;
					display: flex;
					align-items: center;
				}
				.item-box {
					width: 0;
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 18px;
					margin-right: 10px;
					padding-right: 10px;
					border-right: 1px dashed #dadada;
					div {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					span {
						color: $--seller-primary;
					}
				}
				.name {
					width: 60%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.time {
					width: 70px;
					flex-shrink: 0;
					text-align: center;
				}
				.store-text {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					i {
						margin-right: 6px;
					}
				}
			}
		}
		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}

			.user-info {
				h1 {
					font-size: 14px;
					line-height: 20px;
				}

				p {
					font-size: 12px;
					line-height: 16px;
					color: #999999;
				}
			}

			.item-link {
				color: $--seller-primary;
			}

			.item-link:hover {
				text-decoration: underline;
			}
			.action-item {
				cursor: pointer;
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
	::v-deep .el-textarea {
		.el-input__count {
			line-height: 1em;
		}
	}
</style>
